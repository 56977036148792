import axios from "axios";

// functions
import { getApiUrl } from "../../functions/Commons";

interface IFetchHospitalList {
  idToken: string;
  page?: number;
  limit?: number;
  sortColumn?: string;
  sortType?: number;
  filterText?: string;
  filterList?: string[];
}

export default ({
  idToken,
  page = 1,
  limit = 20,
  sortColumn = "",
  sortType = 0,
  filterText = "",
  filterList = [],
}: IFetchHospitalList) => {
  let dispatch = {};
  const url = `${getApiUrl()}/v1/hospital/hospitalListForReport`;

  const filter: { hospCode?: string; hospName?: string } = {};

  if (filterList.length) {
    if (filterList[0].length) {
      const hospCode = filterList[0];
      filter.hospCode = hospCode;
    }

    if (filterList[1].length) {
      const hospName = filterList[1];
      filter.hospName = hospName;
    }
  }

  return axios
    .get(url, {
      headers: { authorization: `Bearer ${idToken}` },
      params: {
        page,
        limit,
        sortColumn,
        sortType,
        filterText,
        filter,
      },
    })
    .then((res) => {
      dispatch = {
        hospitalList: res.data.result,
        FetchingStatus: false,
      };

      return dispatch;
    })
    .catch(() => {
      console.log("fetch hospital list axios error");

      dispatch = {
        FetchingStatus: false,
      };

      return dispatch;
    });
};
