import React, { useEffect, useState, useRef } from "react";
import {
  Card,
  CardContent,
  Typography,
  Grid,
  Avatar,
  Box,
  TextField,
  FormControl,
  Select,
  MenuItem,
  IconButton,
  Divider,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Button,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import fetchLoginHistory from "../../actions/fetchLoginHistory.action";
import { RootState } from "@/reducers";
import { DateTimeToStrDatetime } from "../../functions/FuncDateTimes";
import { deepOrange, blueGrey } from "@mui/material/colors";
import SearchIcon from "@mui/icons-material/Search";
import RefreshIcon from "@mui/icons-material/Refresh";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import dayjs, { Dayjs } from "dayjs";
import Chart from "react-apexcharts";
import { DateRange, DateRangePicker } from "@mui/lab";
import { shortHospName } from "../../functions/FuncPerjer";
import { Ability, AbilityTuple, Subject, MongoQuery } from "@casl/ability";
import { AnyObject } from "@casl/ability/dist/types/types";
import { PermissionEvent, PermissionService } from "../../constants/permission";

interface ILoginHistory {
  username: string;
  roleId: string;
  hospCode: string;
  loginTime: string;
  role?: { role: string };
  hospital?: { hospCode: string; hospName: string };
}

interface IGroupedDataByHospCode {
  [hospCode: string]: {
    [role: string]: {
      [username: string]: ILoginHistory[];
    };
  };
}

const LoginHistory = () => {
  const dispatch = useDispatch();
  const { appData } = useSelector((state: RootState) => state);
  const { idToken, loginHistory, permissionRules } = appData;

  const isLargeData = loginHistory && loginHistory.length > 1000;

  const [filteredLoginHistory, setFilteredLoginHistory] = useState<
    ILoginHistory[]
  >([]);
  const [dateRange, setDateRange] = useState<DateRange<Dayjs>>([
    dayjs(),
    dayjs(),
  ]);
  const [usernameFilter, setUsernameFilter] = useState<string>("");
  const [roleFilter, setRoleFilter] = useState<string>("");
  const [availableRoles, setAvailableRoles] = useState<string[]>([]);
  const [ability, setAbility] =
    useState<Ability<AbilityTuple<string, Subject>, MongoQuery<AnyObject>>>();
  const [superAdmin, setSuperAdmin] = useState({
    status: false,
    hospitalFilter: "",
  });
  const [expandAll, setExpandAll] = useState(true);
  const [expandedStates, setExpandedStates] = useState<Record<string, boolean>>(
    {}
  );

  const groupedDataRef = useRef<IGroupedDataByHospCode>({});

  useEffect(() => {
    const startDate = dateRange[0]?.startOf("day").toISOString();
    const endDate = dateRange[1]?.endOf("day").toISOString();
    if (startDate && endDate) {
      dispatch(fetchLoginHistory(idToken, startDate, endDate));
    }
  }, [dispatch, idToken, dateRange]);

  useEffect(() => {
    if (loginHistory) {
      filterLoginHistory();
    }
  }, [
    loginHistory,
    dateRange,
    usernameFilter,
    roleFilter,
    superAdmin.hospitalFilter,
  ]);

  useEffect(() => {
    if (!loginHistory) return;

    if (superAdmin.status && superAdmin.hospitalFilter) {
      const filteredByHospital = loginHistory
        ? getAvailableRoles(
            loginHistory.filter(
              (entry: ILoginHistory) =>
                entry.hospital?.hospName === superAdmin.hospitalFilter
            )
          )
        : [];

      setAvailableRoles(filteredByHospital);
      if (!filteredByHospital.includes(roleFilter)) {
        setRoleFilter("");
      }
    } else {
      const roles = getAvailableRoles(loginHistory);

      setAvailableRoles(roles);
      if (!roles.includes(roleFilter)) {
        setRoleFilter("");
      }
    }
  }, [superAdmin.hospitalFilter, loginHistory]);

  useEffect(() => {
    setSuperAdmin(
      ability
        ? {
            status:
              ability.can(
                PermissionEvent.READ,
                PermissionService.LOGIN_HISTORY.ANY_HOSPITAL_LOGIN_HISTORY
              ) ||
              ability.can(
                PermissionEvent.READ,
                PermissionService.LOGIN_HISTORY.CHILD_HOSPITAL_LOGIN_HISTORY
              ),
            hospitalFilter: "",
          }
        : { status: false, hospitalFilter: "" }
    );
  }, [ability]);

  useEffect(() => {
    if (permissionRules) {
      setAbility(new Ability(permissionRules));
    }
  }, [permissionRules]);

  const toggleExpandAll = () => {
    setExpandAll((prev) => !prev);

    const updatedStates: Record<string, boolean> = {};
    Object.entries(groupedDataRef.current).forEach(([hospCode, roles]) => {
      updatedStates[hospCode] = !expandAll;
      Object.keys(roles).forEach((role) => {
        updatedStates[`${hospCode}-${role}`] = !expandAll;
      });
    });

    setExpandedStates(updatedStates);
  };

  const handleAccordionToggle = (key: string) => {
    setExpandedStates((prev) => ({
      ...prev,
      [key]: !prev[key],
    }));
  };

  const filterLoginHistory = () => {
    let filtered = loginHistory || [];

    if (usernameFilter) {
      filtered = filtered.filter((entry: ILoginHistory) =>
        entry.username.toLowerCase().includes(usernameFilter.toLowerCase())
      );
    }

    if (roleFilter) {
      filtered = filtered.filter(
        (entry: ILoginHistory) => entry.role?.role === roleFilter
      );
    }

    if (superAdmin.status && superAdmin.hospitalFilter) {
      filtered = filtered.filter(
        (entry: ILoginHistory) =>
          entry.hospital?.hospName === superAdmin.hospitalFilter
      );
    }

    setFilteredLoginHistory(filtered);
  };

  const getAvailableRoles = (data: ILoginHistory[]): string[] => {
    const roles = data
      .map((entry) => entry.role?.role)
      .filter((role): role is string => !!role);
    return Array.from(new Set(roles));
  };

  const getAvailableHospitals = (data: ILoginHistory[]): string[] => {
    if (!superAdmin.status) return [];
    const hospitals = data
      .map((entry) => entry.hospital?.hospName)
      .filter((hosp): hosp is string => !!hosp);
    return Array.from(new Set(hospitals));
  };

  const availableHospitals = loginHistory
    ? getAvailableHospitals(loginHistory)
    : [];

  const handleReset = () => {
    setDateRange([dayjs(), dayjs()]);
    setUsernameFilter("");
    setRoleFilter("");
    setFilteredLoginHistory(loginHistory);
    setSuperAdmin({ ...superAdmin, hospitalFilter: "" });
  };

  const groupByHospCodeRole = (
    data: ILoginHistory[]
  ): { [hospCode: string]: { [role: string]: number } } => {
    if (!data || !data.length) return {};

    return data.reduce(
      (acc: { [hospCode: string]: { [role: string]: number } }, entry) => {
        const hospCode = `${entry.hospCode} ${shortHospName(
          entry.hospital?.hospName
        )}`;
        const role = entry.role?.role;

        if (!role || !hospCode) return acc;

        if (!acc[hospCode]) {
          acc[hospCode] = {};
        }

        if (!acc[hospCode][role]) {
          acc[hospCode][role] = 0;
        }

        acc[hospCode][role] += 1;

        return acc;
      },
      {}
    );
  };

  const groupByHospCodeRoleAndUser = (
    data: ILoginHistory[]
  ): IGroupedDataByHospCode => {
    if (!data || !data.length) return {};

    return data.reduce((acc: IGroupedDataByHospCode, entry: ILoginHistory) => {
      const hospital = `${entry.hospCode} ${shortHospName(
        entry.hospital?.hospName
      )}`;
      const role = entry.role?.role;
      const username = entry.username;

      if (!role || !hospital || !username) return acc;

      if (!acc[hospital]) {
        acc[hospital] = {};
      }

      if (!acc[hospital][role]) {
        acc[hospital][role] = {};
      }

      if (!acc[hospital][role][username]) {
        acc[hospital][role][username] = [];
      }

      acc[hospital][role][username].push(entry);

      return acc;
    }, {});
  };

  groupedDataRef.current = groupByHospCodeRoleAndUser(filteredLoginHistory);

  const chartSeries = (() => {
    if (isLargeData) {
      const groupedData = groupByHospCodeRole(filteredLoginHistory);

      if (superAdmin.status) {
        const roles = Array.from(
          new Set(
            Object.values(groupedData).flatMap((rolesObj) =>
              Object.keys(rolesObj)
            )
          )
        );

        return roles.map((role) => ({
          name: role,
          data: Object.entries(groupedData).map(([hospCode, roles]) => ({
            x: hospCode,
            y: roles[role] || 0,
          })),
        }));
      } else {
        return Object.entries(groupedData).map(([hospital, roles]) => ({
          name: hospital,
          data: Object.entries(roles).map(([role, count]) => ({
            x: role,
            y: count,
          })),
        }));
      }
    } else {
      return filteredLoginHistory.map((entry: ILoginHistory) => ({
        name: entry.username,
        data: [
          {
            x: dayjs(entry.loginTime)
              .add(7, "hour")
              .format("YYYY-MM-DD HH:mm:ss"),
            y: 1,
          },
        ],
      }));
    }
  })();

  const chartOptions: ApexCharts.ApexOptions = {
    chart: {
      type: isLargeData ? "bar" : "line",
    },
    xaxis: {
      type: isLargeData ? "category" : "datetime",
    },
    yaxis: {
      title: {
        text: "Logins",
      },
    },
    plotOptions: {
      bar: {
        distributed: true,
      },
    },
    tooltip: {
      x: superAdmin.status
        ? {
            formatter: (value, { seriesIndex, w }) => {
              const hospital = value;

              if (isLargeData) {
                const roleName = w.globals.seriesNames[seriesIndex];
                const roleDetails =
                  groupedDataRef.current?.[hospital]?.[roleName];

                const entries = Object.entries(roleDetails).map(
                  ([username, details], index) => {
                    if (index > 59) return null;
                    return `${index + 1}. ${username}: ${details.length} ครั้ง`;
                  }
                );

                if (entries.length > 60) {
                  entries.push(`และอีก ${entries.length - 60} รายการ`);
                }

                const rows = entries.reduce(
                  (acc: string[][], record, index) => {
                    if (record !== null) {
                      const rowIndex = Math.floor(
                        index / (entries.length > 15 ? 4 : 1)
                      );
                      if (!acc[rowIndex]) acc[rowIndex] = [];
                      acc[rowIndex].push(record);
                    }
                    return acc;
                  },
                  []
                );

                const tableRows = rows
                  .map(
                    (row) =>
                      `<tr>${row
                        .map(
                          (col) =>
                            `<td style="padding: 0 10px; white-space: nowrap;">${col}</td>`
                        )
                        .join("")}</tr>`
                  )
                  .join("");

                return `
                <b>วันที่:</b> ${dayjs(dateRange[0]).format(
                  "DD/MM/YYYY"
                )} - ${dayjs(dateRange[1]).format("DD/MM/YYYY")}
                <br>
                <b>Role:</b> ${roleName}
                <br>
                <b>${hospital}</b>
                <br>
                <b>รายละเอียด:</b>
                <table border="1" style="border-collapse: collapse; width: 100%; text-align: left;">
                  ${tableRows}
                </table>
              `;
              } else {
                return dayjs(value)
                  .subtract(7, "hour")
                  .format("DD/MM/YYYY HH:mm:ss");
              }
            },
          }
        : {
            formatter: (value) => {
              if (isLargeData) {
                const roleDetails = Object.entries(groupedDataRef.current)
                  .map(([hospCode, roles]) => {
                    const entries = Object.entries(roles[value])
                      .slice(0, 60)
                      .map(
                        ([username, entries], index) =>
                          `${index + 1}. ${username}: ${entries.length} ครั้ง`
                      );
                    if (Object.keys(roles[value]).length > 60) {
                      entries.push(
                        `และอีก ${Object.keys(roles[value]).length - 60} รายการ`
                      );
                    }

                    const rows = entries.reduce(
                      (acc: any[][], record, index) => {
                        const rowIndex = Math.floor(
                          index / (entries.length > 15 ? 4 : 1)
                        );
                        if (!acc[rowIndex]) acc[rowIndex] = [];
                        acc[rowIndex].push(record);
                        return acc;
                      },
                      []
                    );

                    const tableRows = rows
                      .map(
                        (row) =>
                          `<tr>${row
                            .map(
                              (col) =>
                                `<td style="padding: 0 10px; white-space: nowrap;">${col}</td>`
                            )
                            .join("")}</tr>`
                      )
                      .join("");

                    return `
                    <b>${hospCode}</b>
                    <br>
                    <b>รายละเอียด</b>
                    <br>
                    <table border="1" style="border-collapse: collapse; width: 100%; text-align: left;">
                      ${tableRows}
                    </table>`;
                  })
                  .join("<br><br>");

                return `
                <b>วันที่:</b> ${dayjs(dateRange[0]).format(
                  "DD/MM/YYYY"
                )} - ${dayjs(dateRange[1]).format("DD/MM/YYYY")}
                <br>
                <b>Role:</b> ${value}
                <br>
                ${roleDetails}`;
              } else {
                return dayjs(value)
                  .subtract(7, "hour")
                  .format("DD/MM/YYYY HH:mm:ss");
              }
            },
          },
    },
    legend: {
      show: false,
    },
    dataLabels: {
      enabled: false,
    },
  };

  return (
    <Box sx={{ padding: 3, marginTop: 8 }}>
      <Typography variant="h4" gutterBottom>
        Login History Dashboard
      </Typography>

      <Box
        mb={3}
        display="flex"
        gap={2}
        alignItems="center"
        justifyContent="space-between"
      >
        <Box display="flex" gap={1} alignItems="center">
          <TextField
            label="Username"
            value={usernameFilter}
            onChange={(e) => setUsernameFilter(e.target.value)}
            variant="outlined"
            size="small"
          />

          {superAdmin.status === true && (
            <FormControl variant="outlined" size="small">
              <Select
                value={superAdmin.hospitalFilter}
                onChange={(e) =>
                  setSuperAdmin({
                    ...superAdmin,
                    hospitalFilter: e.target.value as string,
                  })
                }
                displayEmpty
              >
                <MenuItem value="">
                  <em>All Hospitals</em>
                </MenuItem>
                {availableHospitals.map((hosp, idx) => (
                  <MenuItem key={idx} value={hosp}>
                    {hosp}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}

          <FormControl variant="outlined" size="small">
            <Select
              value={roleFilter}
              onChange={(e) => setRoleFilter(e.target.value as string)}
              displayEmpty
            >
              <MenuItem value="">
                <em>All Roles</em>
              </MenuItem>
              {availableRoles.map((role, idx) => (
                <MenuItem key={idx} value={role}>
                  {role}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <DateRangePicker
            startText="Start Date"
            endText="End Date"
            value={dateRange}
            minDate={dayjs().subtract(3, "month")}
            maxDate={dayjs()}
            onChange={(newValue) => setDateRange(newValue)}
            renderInput={(startProps, endProps) => (
              <>
                <TextField {...startProps} size="small" />
                <Box sx={{ mx: 1 }}> to </Box>
                <TextField {...endProps} size="small" />
              </>
            )}
          />
        </Box>

        <Box>
          <IconButton color="primary" onClick={filterLoginHistory}>
            <SearchIcon />
          </IconButton>
          <IconButton color="secondary" onClick={handleReset}>
            <RefreshIcon />
          </IconButton>
        </Box>
      </Box>

      <Box mb={5}>
        <Chart
          options={chartOptions}
          series={chartSeries}
          type="bar"
          height={400}
        />
      </Box>

      <Box display="flex" justifyContent="flex-end" mb={2}>
        <Button
          variant="outlined"
          color="primary"
          onClick={toggleExpandAll}
          sx={{ borderRadius: 6 }}
          size="small"
          endIcon={expandAll ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        >
          {expandAll ? "Collapse All" : "Expand All"}
        </Button>
      </Box>
      <Grid container spacing={3}>
        {Object.entries(groupedDataRef.current).map(
          ([hospital, roles], index) => (
            <Grid item xs={12} key={index}>
              <Accordion
                expanded={expandedStates[hospital] ?? expandAll}
                onChange={() => handleAccordionToggle(hospital)}
              >
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography variant="h5" color={blueGrey[700]}>
                    {hospital}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  {Object.entries(roles).map(([role, users]) => (
                    <Accordion
                      key={role}
                      expanded={
                        expandedStates[`${hospital}-${role}`] ?? expandAll
                      }
                      onChange={() =>
                        handleAccordionToggle(`${hospital}-${role}`)
                      }
                    >
                      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <Typography variant="h6" color={blueGrey[600]}>
                          Role: {role}
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Grid container spacing={2} alignItems="stretch">
                          {Object.entries(users).map(
                            ([username, entries], index) => (
                              <Grid item xs={12} sm={6} md={4} key={username}>
                                <Card
                                  variant="outlined"
                                  sx={{
                                    display: "flex",
                                    flexDirection: "column",
                                    height: "100%",
                                  }}
                                >
                                  <CardContent sx={{ flexGrow: 1 }}>
                                    <Box
                                      display="flex"
                                      alignItems="center"
                                      gap={2}
                                    >
                                      <Avatar
                                        sx={{ bgcolor: deepOrange[500] }}
                                        alt={username}
                                      >
                                        {index + 1}
                                      </Avatar>
                                      <Box>
                                        <Typography variant="body1">
                                          Username: {username}
                                        </Typography>
                                        <Typography variant="body2">
                                          Logins: {entries.length}
                                        </Typography>
                                      </Box>
                                    </Box>
                                    <Divider sx={{ my: 1 }} />

                                    <Box
                                      sx={{
                                        overflowY: "auto",
                                        maxHeight: "190px",
                                        display: "flex",
                                        flexDirection: "column",
                                      }}
                                    >
                                      {entries.map((entry, idx) => (
                                        <Typography
                                          key={idx}
                                          variant="body2"
                                          color="textSecondary"
                                        >
                                          {idx + 1}.{" "}
                                          {DateTimeToStrDatetime(
                                            entry.loginTime
                                          )}
                                        </Typography>
                                      ))}
                                    </Box>
                                  </CardContent>
                                </Card>
                              </Grid>
                            )
                          )}
                        </Grid>
                      </AccordionDetails>
                    </Accordion>
                  ))}
                </AccordionDetails>
              </Accordion>
            </Grid>
          )
        )}
      </Grid>
    </Box>
  );
};

export default LoginHistory;
