// NOTE: If you edit something in this file, don't forget to update the constant in the frontend as well.
// (packages/schema/v10/constants/PermissionService.js)
// (packages/schema/v10/constants/PermissionEvent.js)

export const PermissionService = {
  REFERRAL: {
    OWN_HOSPITAL_REFERRAL: "OwnHospitalReferral",
    ANY_HOSPITAL_REFERRAL: "AnyHospitalReferral",
    CHILD_HOSPITAL_REFERRAL: "ChildHospitalReferral",
  },
  REPORT: {
    OWN_HOSPITAL_REPORT: "OwnHospitalReport",
    ANY_HOSPITAL_REPORT: "AnyHospitalReport",
    CHILD_HOSPITAL_REPORT: "ChildHospitalReport",
  },
  MANAGEMENT: {
    USER_MANAGEMENT: {
      USER_MEMBER: "UserMember",
      OWN_HOSPITAL_USER: "OwnHospitalUser",
      ANY_HOSPITAL_USER: "AnyHospitalUser",
      CHILD_HOSPITAL_USER: "ChildHospitalUser",
    },
    HOSPITAL_MANAGEMENT: "HospitalManagement",
    PERMISSION_MANAGEMENT: "PermissionManagement",
  },
  KNOWLEDGE_GRAPH: "KnowledgeGraph",
  POPULATION_HEALTH: "PopulationHealth",
  ONLINE_STATUS: "OnlineStatus",
  SAMPLE_DATA: "SampleData",
  SETTING_REASON_REFERRAL: "SettingReasonReferral",
  EDIT_REFER_STATUS: "EditReferStatus",
  APPOINTMENT_OPD: "AppointmentOPD",
  LOGIN_HISTORY: {
    OWN_HOSPITAL_LOGIN_HISTORY: "OwnHospitalLoginHistory",
    ANY_HOSPITAL_LOGIN_HISTORY: "AnyHospitalLoginHistory",
    CHILD_HOSPITAL_LOGIN_HISTORY: "ChildHospitalLoginHistory",
  },
};

export enum PermissionEvent {
  CREATE = "CREATE",
  READ = "READ",
  UPDATE = "UPDATE",
  DELETE = "DELETE",
}
