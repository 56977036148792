import { RootState } from "../../reducers";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { saveAs } from "file-saver";
import { write, utils } from "xlsx";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import MyHospFilter from "./components/MyHospFilter";
import { Button, TextField } from "@mui/material";
import { IDoctor, TClinic } from "@/types/clinic";
import { Search } from "@mui/icons-material";
import { IReferral } from "../../types/refers";
import ReferCard from "../Refers/ReferCard";
import { PermissionEvent, PermissionService } from "../../constants/permission";
import { Ability } from "@casl/ability";
import Swal from "sweetalert2";
import fetchSchedules from "../../api/AppointmentOPD/fetchSchedule";
import rejectRefer from "../../api/rejectRefer.service";
import acceptRefer from "../../api/acceptRefer.service";
import exportExcel from "../../api/AppointmentOPD/exportExcel";
import pullAppointment from "../../api/AppointmentOPD/pullAppointment";

import DateRangePicker, { DateRange } from "@mui/lab/DateRangePicker";
import { ThaiDate } from "../../functions/FuncDateTimes";
import { canManageReferral } from "../../functions/refer";

function HospitalAppointment() {
  const { appData } = useSelector((state: RootState) => state);
  const {
    idToken,
    loginData: { hospCode },
    permissionRules,
  } = appData;
  const [dateRange, setDateRange] = useState<DateRange<Date>>([
    new Date(),
    new Date(),
  ]);
  const [selectedDoctor, setSelectedDoctor] = useState<IDoctor | null>(null);
  const [selectedClinic, setSelectedClinic] = useState<TClinic>();
  const [refers, setRefers] = useState<IReferral[]>([]);
  const [ability, setAbility] = useState<any>();
  const handleSelectedClinic = (clinic: TClinic) => setSelectedClinic(clinic);
  const handleSelectedDoctor = (doctor: IDoctor) => setSelectedDoctor(doctor);

  const handleFetchSchedules = async (hospCode: string, doctorId?: string) => {
    try {
      const [start, end] = dateRange;
      if (!doctorId || !hospCode || !selectedClinic?._id || !start || !end) {
        return;
      }
      const data = await fetchSchedules(
        idToken,
        doctorId,
        hospCode,
        selectedClinic._id,
        new Date(start),
        new Date(end),
        "VERIFY"
      );
      const refersData = data
        .filter((appointment) => appointment.refersAppointment.length > 0)
        .map((appointment) => appointment.refersAppointment)
        .flat();
      setRefers(refersData);
    } catch (error) {
      console.log("fetch schedules error", error);
    }
  };
  const onAcceptAppointment = async (
    referral: IReferral,
    appointmentPoint: string,
    appointmentNotes: string,
    appointmentDateTime: string
  ) => {
    try {
      if (!referral) throw Error("selected appointment");

      const bid = referral.bid;
      const appointmentInfo = {
        appointmentPoint,
        appointmentNotes,
        appointmentDateTime,
      };

      await acceptRefer(idToken, bid, appointmentInfo);
      Swal.fire("Saved!", "", "success");
    } catch (error) {
      Swal.fire("Saved!", "", "error");
    }
  };

  const onRejectAppointment = async (referral: IReferral, reason: string) => {
    try {
      if (!referral) throw Error("selected appointment");
      await rejectRefer(idToken, referral.bid, reason);
      Swal.fire("Saved!", "", "success");
    } catch (error) {
      Swal.fire("Saved!", "", "error");
    }
  };

  const onPullSchedule = async (referId: string, scheduleId: string) => {
    try {
      const { isConfirmed } = await Swal.fire({
        position: "center",
        title: "ท่านยืนยันที่ดึงรายการออกจากตารางนัดหมายแพทย์ใช่หรือไม่",
        showDenyButton: true,
        confirmButtonText: "ตกลง",
        denyButtonText: `ยกเลิก`,
        customClass: {
          container: "swal2-container",
        },
      });
      if (isConfirmed) {
        await pullAppointment(idToken, scheduleId, referId);
        await handleFetchSchedules(hospCode, selectedDoctor?._id);
        Swal.fire("Saved!", "", "success");
      }
    } catch (error) {
      Swal.fire("Saved!", "", "error");
    }
  };

  const onDownloadExcel = async () => {
    const [start, end] = dateRange;
    if (refers?.length === 0 || !start || !end || !selectedDoctor) {
      return;
    }
    const referList = refers.map((refer) => refer._id);
    const json = await exportExcel(idToken, referList);
    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const strStartDate = new Date(start).toLocaleDateString("en-GB");
    const strEndDate = new Date(end).toLocaleDateString("en-GB");
    const fileExtension = ".xlsx";
    const fileName = `${selectedDoctor._id}_${strStartDate}_${strEndDate}`;
    const ws = utils.json_to_sheet(json);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    saveAs(data, fileName + fileExtension);
  };

  useEffect(() => setAbility(new Ability(permissionRules)), [permissionRules]);

  const refersCardItem = (refers: IReferral[]) => {
    return refers.map((refer, index) => (
      <div key={index}>
        <div className="flex mx-2 h-8 items-center justify-between">
          <span className="text-gray-500">
            วันที่แพทย์ {ThaiDate(refer.scheduleDateTime)}
          </span>
          {refer.appointmentDateTime !== refer?.scheduleDateTime && (
            <Button
              onClick={() => onPullSchedule(refer._id, refer?.scheduleId || "")}
              color="warning"
            >
              ดึงออกจากตารางแพทย์
            </Button>
          )}
        </div>
        <ReferCard
          referral={refer}
          avatarText={index}
          canEditReferStatus={ability.can(
            PermissionEvent.UPDATE,
            PermissionService.EDIT_REFER_STATUS
          )}
          canManageRefer={canManageReferral(ability)}
          onAcceptRefer={(appointmentInfo: {
            appointmentPoint: string;
            appointmentNotes: string;
            appointmentDateTime: string;
          }) => {
            onAcceptAppointment(
              refer,
              appointmentInfo.appointmentPoint,
              appointmentInfo.appointmentNotes,
              appointmentInfo.appointmentDateTime
            );
          }}
          onRejectRefer={(reason: string) => {
            onRejectAppointment(refer, reason);
          }}
        />
      </div>
    ));
  };
  return (
    <div className="grid mt-14">
      <div className="grid grid-cols-2 gap-4 bg-white p-6">
        <div>
          <MyHospFilter
            token={idToken}
            hospCode={hospCode}
            onSelectedDoctor={handleSelectedDoctor}
            onSelectedClinic={handleSelectedClinic}
          />
        </div>
        <div className="flex">
          <DateRangePicker
            value={dateRange}
            inputFormat="DD/MM/YYYY"
            onChange={(date) => setDateRange(date)}
            renderInput={(startProps, endProps) => (
              <>
                <TextField id="start-date" {...startProps} />
                <TextField
                  {...endProps}
                  id="end-date"
                  InputProps={{
                    endAdornment: (
                      <CalendarTodayIcon className="text-gray-500" />
                    ),
                  }}
                />
              </>
            )}
          />
          <Button
            variant="contained"
            onClick={() => handleFetchSchedules(hospCode, selectedDoctor?._id)}
            disabled={!selectedDoctor}
            sx={{
              bgcolor: "white",
              color: "gray",
              borderRadius: "20px",
              mx: 2,
              p: 2,
              ":hover": {
                backgroundColor: "#fff",
                color: "#132043",
                border: "0.5px solid",
              },
            }}
          >
            <Search />
          </Button>
          <Button
            onClick={onDownloadExcel}
            disabled={refers.length === 0}
            variant="contained"
            sx={{
              bgcolor: "white",
              color: "gray",
              borderRadius: "20px",
              mx: 2,
              p: 2,
              ":hover": {
                backgroundColor: "#fff",
                color: "#132043",
                border: "0.5px solid",
              },
            }}
          >
            ดาวน์โหลด
          </Button>
        </div>
      </div>

      <div
        className="grid gap-4 p-4 bg-gray-100
            md:grid-cols-2
            lg:grid-cols-3"
      >
        {refersCardItem(refers)}
      </div>
    </div>
  );
}

export default HospitalAppointment;
