import { IReferral } from "../types/refers";
import { COMPLETE, REJECTED } from "../constants/referralStatus";
import { Ability } from "@casl/ability";
import { PermissionEvent, PermissionService } from "../constants/permission";

const getReferColor = (referral: IReferral) => {
  let referralClass = "";

  if (referral?.ptDeceasedBoolean) {
    referralClass = "referrals-card-deceased";
  } else if (referral?.status?.toUpperCase() === COMPLETE) {
    referralClass = "referrals-card-complete";
  } else if (referral?.status?.toUpperCase() === REJECTED) {
    referralClass = "referrals-card-rejected";
  }

  return referralClass;
};

const canManageReferral = (ability?: Ability): boolean => {
  if (!ability) return false;

  const referralPermissions = [
    PermissionService.REFERRAL.ANY_HOSPITAL_REFERRAL,
    PermissionService.REFERRAL.OWN_HOSPITAL_REFERRAL,
    PermissionService.REFERRAL.CHILD_HOSPITAL_REFERRAL,
  ];

  return referralPermissions.some((service) =>
    ability.can(PermissionEvent.UPDATE, service)
  );
};

export { getReferColor, canManageReferral };
