import React from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";

export default function PermissionNavbar() {
  return (
    <AppBar id="refer-navbar" position="static" className="mt-16 !bg-white">
      <Toolbar variant="dense" className="py-4">
        <div className="flex flex-grow items-center">
          <h5 className="text-xl text-sky-500 mr-8">Permission</h5>
        </div>
      </Toolbar>
    </AppBar>
  );
}
