import React, { useState } from "react";
import ReferNavbar from "./ReferNavbar";
import { IReferral, ReferralType } from "../../types/refers";
import ReferCard from "./ReferCard";
import { Box } from "@mui/material";

function ReferOut() {
  const [referList, setReferList] = useState<IReferral[]>([]);

  return (
    <>
      <ReferNavbar
        referType={ReferralType.REFER_OUT}
        onUpdateReferList={setReferList}
      />

      {referList?.length > 0 ? (
        <Box
          sx={{
            display: "grid",
            gap: 2,
            p: 2,
            bgcolor: "grey.100",
            gridTemplateColumns: {
              md: "repeat(2, 1fr)",
              lg: "repeat(3, 1fr)",
            },
          }}
        >
          {referList.map((refer, index) => (
            <ReferCard
              avatarText={referList.length - index}
              referral={refer}
              key={refer.bid}
            />
          ))}
        </Box>
      ) : (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "80%",
          }}
        >
          <h3>ไม่พบรีเฟอร์</h3>
        </Box>
      )}
    </>
  );
}

export default ReferOut;
