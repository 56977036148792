import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2";
import { SnackbarProvider } from "notistack";
import IconButton from "@mui/material/IconButton";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MUIDataTable, { MUIDataTableOptions } from "mui-datatables";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import PermissionNavbar from "./PermissionNavbar";
import PermissionForm from "./PermissionForm";
import fetchRoles from "../../actions/PermissionManagement/fetchRoles.action";
import changeRoleStatus from "../../api/PermissionManagement/changeRoleStatus.service";

export default function PermissionMain() {
  const dispatch = useDispatch();
  const { appData }: any = useSelector((state) => state);
  const { idToken, permissionManagement } = appData;

  const [formEvent, setFormEvent] = useState<"CREATE" | "EDIT">("CREATE");
  const [roles, setRoles] = useState<{ docs: any[]; totalDocs: number }>();
  const [change, setChange] = useState(false);

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [selectedRowData, setSelectedRowData] = useState<any[]>();
  const [permissionFormOpen, setPermissionFormOpen] = useState(false);

  const [page, setPage] = useState(1);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (
    event: React.MouseEvent<HTMLButtonElement>,
    tableMeta: any
  ) => {
    setAnchorEl(event.currentTarget);
    setSelectedRowData(tableMeta.rowData);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDeleteRole = () => {
    if (selectedRowData) {
      changeRoleStatus({ idToken, roleId: selectedRowData[0] })
        .then(() => {
          setAnchorEl(null);
          dispatch(fetchRoles({ idToken, page }));
          setSnackbarOpen(true);
        })
        .catch((err) => {
          Swal.fire({
            title: err.response.statusText,
            text: err.response.data,
            icon: "error",
          });
        });
    }
  };

  const handleSnackbarClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setSnackbarOpen(false);
  };

  const options: MUIDataTableOptions = {
    responsive: "standard",
    filter: false,
    selectableRows: "none",
    download: false,
    print: false,
    viewColumns: false,
    search: false,
    serverSide: true,
    rowsPerPage: 5,
    rowsPerPageOptions: [5],
    count: roles ? roles.totalDocs : 0,
    onChangePage(currentPage: number) {
      setPage(currentPage + 1);
    },
  };

  const columns = [
    {
      name: "id",
      options: {
        display: false,
      },
    },
    {
      name: "Role",
    },
    {
      name: "Permission",
      options: {
        customBodyRender: (values: string[]) => (
          <Stack direction="row" spacing={1} maxWidth={650} overflow="scroll">
            {values.map((value, index) => (
              <Chip
                key={index}
                label={value}
                color="primary"
                variant="outlined"
              />
            ))}
          </Stack>
        ),
      },
    },
    {
      name: "Status",
      options: {
        customBodyRender: (value: "Active" | "Inactive") => (
          <div
            className={`border rounded w-fit p-1
            ${value === "Active" && "border-sky-300 bg-sky-50 text-sky-500"}
            ${
              value === "Inactive" &&
              "border-orange-300 bg-orange-50 text-orange-400"
            }`}
          >
            {value}
          </div>
        ),
      },
    },
    {
      name: "",
      options: {
        filter: false,
        customBodyRender: (value: any, tableMeta: any) => (
          <div className="button-wrapper text-right">
            <IconButton
              id="basic-button"
              aria-controls={open ? "basic-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              onClick={(event) => handleClick(event, tableMeta)}
            >
              <MoreVertIcon />
            </IconButton>
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleClose}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
            >
              <MenuItem
                onClick={() => {
                  setFormEvent("EDIT");
                  setPermissionFormOpen(true);
                }}
              >
                Edit permission
              </MenuItem>
              <MenuItem onClick={handleDeleteRole}>
                {tableMeta.rowData[3] === "Active"
                  ? "Deactivate Role"
                  : "Activate Role"}
              </MenuItem>
            </Menu>
          </div>
        ),
      },
    },
  ];

  useEffect(() => {
    dispatch(fetchRoles({ idToken, page }));
  }, [page]);

  useEffect(() => {
    if (permissionManagement.roles) {
      setRoles(permissionManagement.roles);
    }
  }, [permissionManagement.roles]);

  useEffect(() => {
    setAnchorEl(null);
    if (!permissionFormOpen && change) {
      dispatch(fetchRoles({ idToken, page }));
    }
  }, [permissionFormOpen, change]);

  return (
    <>
      <PermissionNavbar />
      <div className="pt-6 px-12">
        {roles && (
          <MUIDataTable
            title={
              <Button
                variant="outlined"
                startIcon={<AddCircleIcon />}
                className="!rounded-full !capitalize"
                onClick={() => {
                  setFormEvent("CREATE");
                  setSelectedRowData(undefined);
                  setPermissionFormOpen(true);
                }}
              >
                Create New Role
              </Button>
            }
            data={roles.docs}
            columns={columns}
            options={options}
          />
        )}
      </div>

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity="success"
          sx={{ width: "100%" }}
        >
          the permission details has been successfully updated.
        </Alert>
      </Snackbar>

      <SnackbarProvider
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        {permissionFormOpen && (
          <PermissionForm
            formEvent={formEvent}
            roleId={selectedRowData && selectedRowData[0]}
            open={permissionFormOpen}
            onClose={(change) => {
              setPermissionFormOpen(false), setChange(change);
            }}
          />
        )}
      </SnackbarProvider>
    </>
  );
}
