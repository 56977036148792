import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Swal from "sweetalert2";
import { Ability, AbilityTuple, Subject, MongoQuery } from "@casl/ability";
import { AnyObject } from "@casl/ability/dist/types/types";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import RefreshIcon from "@mui/icons-material/Refresh";
import Button from "@mui/material/Button";
import DateRangePicker, { DateRange } from "@mui/lab/DateRangePicker";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import Autocomplete from "@mui/material/Autocomplete";
import CircularProgress from "@mui/material/CircularProgress";
import { ReferralType } from "../../types/refers";
import { RootState } from "../../reducers";
import { PermissionEvent, PermissionService } from "../../constants/permission";
import fetchHospitalListForReport from "../../actions/Report/fetchHospitalListForReport.action";
import ListboxComponent from "../Utility/ListBoxComponents";
import "../../styles/reports/ReportNavbar.scss";
import { shortHospName } from "../../functions/FuncPerjer";
import { ISelectedHospital } from "@/types/report";
import dayjs from "dayjs";

interface IReportNavbar {
  onProcess(
    selectedHospital: ISelectedHospital,
    encounterType: ReferralType,
    startDate: Date,
    endDate: Date
  ): void;
}

export default function ReportNavbar({ onProcess }: IReportNavbar) {
  const dispatch = useDispatch();
  const { appData } = useSelector((state: RootState) => state);
  const { permissionRules, loginData } = appData;

  const [dateRange, setDateRange] = useState<DateRange<Date>>([
    new Date(),
    new Date(),
  ]);
  const [encounterType, setEncounterType] = useState<ReferralType | "">("");
  const [selectableHospitals, setSelectableHospitals] = useState(false);
  const [selectedHospital, setSelectedHospital] = useState<ISelectedHospital>();
  const [ability, setAbility] =
    useState<Ability<AbilityTuple<string, Subject>, MongoQuery<AnyObject>>>();

  const handleProcess = () => {
    if (!selectedHospital || !encounterType || !dateRange[0] || !dateRange[1]) {
      Swal.fire({ title: "กรุณาเลือกประเภทรายงาน", icon: "warning" });
      return;
    }

    const start = new Date(dateRange[0]);
    const end = new Date(dateRange[1]);
    start.setHours(0, 0, 0, 0);
    end.setHours(23, 59, 59, 999);
    onProcess(selectedHospital, encounterType, start, end);
  };

  const handleFetchHospitalList = () => {
    dispatch(
      fetchHospitalListForReport({
        idToken: appData.idToken,
        limit: 1000,
      })
    );
  };

  useEffect(() => {
    if (ability) {
      if (
        ability.can(
          PermissionEvent.READ,
          PermissionService.REPORT.ANY_HOSPITAL_REPORT
        ) ||
        ability.can(
          PermissionEvent.READ,
          PermissionService.REPORT.CHILD_HOSPITAL_REPORT
        )
      ) {
        handleFetchHospitalList();
        setSelectableHospitals(true);
      } else {
        if (!loginData) {
          return;
        }

        const hospCode = loginData.hospCode;
        const hospName = loginData.hospName;
        setSelectedHospital({ hospCode, hospName });
      }
    }
  }, [ability]);

  useEffect(() => {
    if (permissionRules) {
      setAbility(new Ability(permissionRules));
    }
  }, [permissionRules]);

  return (
    <AppBar id="report-navbar" position="static" className="mt-16 !bg-white">
      <Toolbar variant="dense" className="py-4">
        <div className="flex flex-grow items-center">
          <h5 className="text-xl text-sky-500 mr-8">Report</h5>

          {selectableHospitals && appData.hospitalList.docs && (
            <div id="hospital-selector" className="mr-2 flex-1">
              <Autocomplete
                disableListWrap
                loading={appData.hospitalList.length <= 0}
                ListboxComponent={ListboxComponent}
                options={appData.hospitalList.docs}
                onChange={(option, value) => {
                  if (
                    value &&
                    Object.prototype.hasOwnProperty.call(value, "hospCode")
                  ) {
                    setSelectedHospital(value);
                  }
                }}
                getOptionLabel={(option: {
                  hospCode: string;
                  hospName: string;
                }) => `${option.hospCode} : ${shortHospName(option.hospName)}`}
                noOptionsText={"Can not find hospital code or hospital name"}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    required
                    placeholder="เลือกโรงพยาบาล"
                    variant="outlined"
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <React.Fragment>
                          {appData.hospitalList.length <= 0 ? (
                            <CircularProgress color="inherit" size={20} />
                          ) : null}
                          {params.InputProps.endAdornment}
                        </React.Fragment>
                      ),
                    }}
                  />
                )}
                renderOption={(props, option) => (
                  <li
                    {...props}
                    className="truncate cursor-pointer pl-2 pt-2 hover:bg-slate-50 "
                    key={option.hospCode}
                  >
                    {`${option.hospCode} : ${shortHospName(option.hospName)}`}
                  </li>
                )}
              />
            </div>
          )}

          <div id="date-picker" className="flex-1">
            <DateRangePicker
              value={dateRange}
              inputFormat="DD/MM/YYYY"
              onChange={(newDateRange: any) => {
                setDateRange(newDateRange);
              }}
              minDate={dayjs().subtract(6, "months")}
              maxDate={dayjs()}
              renderInput={(startProps, endProps) => (
                <>
                  <TextField id="start-date" {...startProps} />
                  <TextField
                    {...endProps}
                    id="end-date"
                    InputProps={{
                      endAdornment: (
                        <CalendarTodayIcon className="text-gray-500" />
                      ),
                    }}
                  />
                </>
              )}
            />
          </div>
        </div>

        <div className="flex gap-x-3 w-2/6">
          <FormControl id="encounter-type" className="flex-auto w-28">
            <Select
              displayEmpty
              className="bg-transparent"
              value={encounterType}
              onChange={(event) => {
                setEncounterType(event.target.value as ReferralType);
              }}
            >
              <MenuItem value="">Encounter type</MenuItem>
              <MenuItem value={ReferralType.REFER_IN}>- Refer In -</MenuItem>
              <MenuItem value={ReferralType.REFER_OUT}>- Refer Out -</MenuItem>
            </Select>
          </FormControl>

          <Button
            startIcon={<RefreshIcon />}
            variant="contained"
            className="!bg-sky-500 !rounded-full"
            onClick={handleProcess}
          >
            Process
          </Button>
        </div>
      </Toolbar>
    </AppBar>
  );
}
